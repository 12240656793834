.dive-selection {
  display: flex;
  justify-content: space-between;
  font-family: "Work Sans", sans-serif;

  .dive-info {
    background-color: rgba(255, 255, 255, 0.05);
    width: 100%;
    position: relative;
    z-index: 100;
    overflow: hidden;
    flex: 1.7;
    &::before {
      content: " ";
      border-radius: 100%;
      position: absolute;
      top: -50%;
      left: -50%;
      height: 70rem;
      width: 70rem;
      z-index: 0;
      background: radial-gradient(
        circle at 50% 50%,
        #2c35c1 0%,
        transparent 100%
      );
      filter: blur(10rem);
    }
    &::after {
      content: " ";
      border-radius: 100%;
      position: absolute;
      top: -60%;
      right: -5%;
      height: 70rem;
      width: 70rem;
      z-index: 0;
      background: radial-gradient(
        circle at 50% 50%,
        #682b7e 0%,
        transparent 100%
      );
      filter: blur(10rem);
    }
  }
  .dive-img {
    flex: 1;
    width: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 30% 0%;
    }
  }
  .container {
    max-width: calc(100% - 5rem);
    height: 100%;
    //add blur to layers below
    margin-left: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 7rem;
    gap: 2rem;
    .dive-title {
      width: 70%;
      z-index: 100;
      overflow: hidden;

      h2 {
        font-size: 10rem;
        color: #fff;
      }
      div {
        overflow: hidden;
      }
    }
    p {
      font-size: 2.4rem;
      width: 70%;
      z-index: 100;
      color: #b8b8b8;
      letter-spacing: 10%;
    }
    &::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
      //linear gradient from top to bottom
      background: linear-gradient(180deg, #2c35c1 0%, #5059d8 100%);
      filter: blur(10rem);
    }
  }
}
.dive {
  .img {
    width: 100%;
    height: 100%;
    min-height: 30vh;
    img {
      width: 100%;
      height: 100%;
      max-height: 60vh;
      object-fit: cover;
      object-position: 30% 0%;
    }
  }
}
@media (max-width: 1440px) {
  .dive-info {
    .container {
      h2 {
        font-size: 7rem !important;
      }
    }
  }
}
@media (max-width: 1024px) {
  .dive-info {
    .container {
      .dive-title {
        padding-top: 5rem;
        h2 {
          width: 90%;
          font-size: 5rem !important;
        }
      }
      p {
        font-size: 220%;
        width: 90%;
      }
    }
  }
}
@media (max-width: 768px) {
  .dive-selection {
    flex-direction: column;

    .dive-info {
      flex: 1;
      min-height: 50vh;
      position: relative;
      .container {
        height: 100%;
        text-align: center;
        align-items: center;

        h2 {
          font-size: 4rem !important;
        }
        p {
          font-size: 180%;
        }
      }
    }
    .dive-img {
      flex: 1;
    }
  }
  .img {
    height: 50vh !important;
    img {
      min-height: 100%;
      object-fit: cover !important;
      object-position: 90% 20% !important;
    }
  }
}
