.try {
  padding-bottom: 20rem;
  font-family: "Work Sans", sans-serif;
  .banner {
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
  .try-info {
    margin-top: -10%;
    display: flex;
    h2 {
      font-size: 10rem;
      color: #fff;
      z-index: 100;
      margin-left: 5rem;
      flex: 0.5;
    }
    img {
      height: 100%;
      object-fit: cover;
      object-position: 20% 30%;
    }
    .calendar-container {
      flex: 1;
      width: 100%;
      display: flex;
      height: 100%;
      .calendar-wrapper {
        background-color: #000;
        position: relative;
        width: 100%;
        min-height: 100%;
        overflow: hidden;
        padding-bottom: 5rem;
        &::after {
          content: " ";
          position: absolute;
          border-radius: 100%;
          bottom: -50%;
          left: -30%;
          height: 60rem;
          width: 60rem;
          background: radial-gradient(
            50% 50% at 50% 50%,
            #411b4f 0%,
            #000000 100%
          );
          //blur
          filter: blur(21px);
          z-index: 1;
        }
      }
      .try-now {
        position: absolute;
        z-index: 100;
        width: 70%;
        left: 15%;
        bottom: 2.5%;
        text-align: center;
        color: #fff;
        font-size: 3rem;
        text-decoration: none;
        background: linear-gradient(90deg, #443290 0%, #682b7e 100%);
        border-radius: 1rem;
        padding: 1rem 2rem;
      }
    }
  }

  .react-calendar {
    padding-top: 5rem;
    width: 70%;
    height: 90%;
    margin: 0 auto;
    z-index: 100;
    position: relative;
    padding-bottom: 5rem;
    .react-calendar__navigation__next2-button,
    .react-calendar__navigation__prev2-button {
      display: none;
    }
    .react-calendar__navigation {
      display: flex;
      margin-bottom: 2rem;
      .react-calendar__navigation__prev-button,
      .react-calendar__navigation__next-button {
        padding: 2rem;
        background-color: transparent;
        border: none;
        color: #fff;
        font-size: 3rem;
        &:disabled {
          color: #5f5f5f;
        }
      }
      .react-calendar__navigation__label {
        border: none;
        background-color: transparent;
        font-size: 3rem;
        color: #fff;
        font-weight: 700;
      }
    }
    .react-calendar__month-view__weekdays__weekday {
      text-align: center;
      margin-bottom: 3rem;
      max-height: 5rem !important;
      abbr {
        text-decoration: none;
        color: #443290;
        font-size: 3rem;
        font-weight: 300;
      }
    }

    .react-calendar__viewContainer {
      height: 100%;
      .react-calendar__month-view {
        height: 100%;
        div {
          .react-calendar__month-view__weekdays {
            height: fit-content;
          }
          .react-calendar__month-view__days {
            height: 100%;
            .react-calendar__tile {
              position: relative;
              background-color: transparent;
              border: none;

              z-index: 100;
              font-weight: 700;
              display: flex;
              padding: 1rem;
              height: 10rem;
              abbr {
                z-index: 2;
                color: #7360c9;
                font-size: 2.4rem;
              }
              &::after {
                content: "";
                position: absolute;
                top: 5%;
                left: 5%;
                width: 90%;
                height: 90%;
                background: linear-gradient(
                  133.92deg,
                  #443290 1.75%,
                  rgba(74, 20, 95, 0.53) 96.43%
                );

                z-index: 0;
              }
              &:hover {
                abbr {
                  position: relative;
                  z-index: 2;
                  color: #411b4f;
                  transition: all 0.3s ease-in-out;
                }
                &::after {
                  background: linear-gradient(
                    133.92deg,
                    #912cc1 1.75%,
                    #6d5cd2 96.43%
                  );
                }
              }
            }
          }
          .react-calendar__tile:disabled {
            abbr {
              color: #5f5f5f;
            }
            &::after {
              background: linear-gradient(
                133.92deg,
                #1c153b 1.75%,
                rgba(39, 11, 49, 0.53) 96.43%
              );
            }
            &:hover {
              abbr {
                color: #5f5f5f;
              }
              &::after {
                background: linear-gradient(
                  133.92deg,
                  #2b205b 1.75%,
                  rgba(66, 18, 84, 0.53) 96.43%
                );
              }
            }
          }
          .react-calendar__tile--active {
            abbr {
              position: relative;
              z-index: 2;
              color: #411b4f !important;
              transition: all 0.3s ease-in-out;
            }
            &::after {
              background: linear-gradient(
                133.92deg,
                #912cc1 1.75%,
                #6d5cd2 96.43%
              ) !important;
              z-index: -1;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1400px) {
  .try {
    .try-info {
      flex-direction: column;
      gap: 5rem;
      align-items: center;
      text-align: center;
    }
    .react-calendar {
      width: 85% !important;
    }
  }
}
@media (max-width: 1100px) {
  .react-calendar__tile {
    height: 7vw !important;
  }
}
@media (max-width: 768px) {
  h2 {
    margin-left: 0 !important;
  }
  .calendar-container {
    flex-direction: column;
    .img {
      flex: 1 !important;

      img {
        width: 100% !important;
        object-fit: cover;
        max-height: 30vh;
      }
    }
    .calendar-wrapper {
      flex: 1 !important;
      .react-calendar__tile {
        height: 15vw !important;
      }
    }
  }
}
@media (max-width: 450px) {
  .img {
    display: none;
  }
}
