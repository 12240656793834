.footer {
  padding-bottom: 10rem;
  nav {
    .logo {
      img {
        display: none !important;
      }
    }
  }
}
