nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5rem;
  margin-right: 5rem;
  z-index: 101;
  background-color: #000000;
  position: relative;
  font-family: "Orbitron", sans-serif;
  a {
    text-decoration: none;
    color: #ffffff;
    font-size: 1.8rem;
  }
  ul {
    display: flex;
    list-style: none;
    gap: 2rem;
  }
  .logo {
    font-size: 2.4rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    img {
      width: 4rem;
      height: 4rem;
      margin-right: 1rem;
    }
  }
}
@media (max-width: 768px) {
  nav {
    flex-direction: column;
    padding-bottom: 1rem;
  }
}
