.about {
  font-family: "Orbitron", sans-serif;
  padding-top: 20rem;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  position: relative;
  overflow: hidden;
  h2 {
    font-size: 10rem;
    color: #fff;
    z-index: 100;
    text-transform: uppercase;
    margin-right: 5rem;
  }
  &::after {
    content: " ";
    border-radius: 100%;
    position: absolute;
    top: 5%;
    right: -10%;
    height: 70rem;
    width: 70rem;
    z-index: 0;
    background: radial-gradient(
      circle at 50% 50%,
      #301273 0%,
      transparent 100%
    );
    //blur
    filter: blur(15rem);
    z-index: -1;
  }
  .about-gallery {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 100%;
    position: relative;
    z-index: 100;
    img {
      width: 100%;
      height: 100%;
      max-height: 70vh;
      object-fit: cover;
    }
    p {
      font-family: "Work Sans", sans-serif;
      margin-left: 5rem;
      margin-right: 4rem;
      grid-column-start: 1;
      grid-column-end: 3;
      text-align: left;
      font-size: 4rem;
      font-weight: 300;
      z-index: 100;
      align-self: center;
      display: flex;
      flex-wrap: wrap;
      span {
        display: inline-block;
        white-space: pre;
      }
    }
  }
  .circle {
    position: relative;
    width: 100%;
    height: 100%;

    .circle1 {
      content: " ";
      position: absolute;
      bottom: -10%;
      right: 0%;
      width: 120rem;
      height: 120rem;
      border-radius: 50%;
      background: radial-gradient(
        33.86% 33.88% at 50% 50%,
        #4a145f 0%,
        rgba(48, 18, 115, 0) 100%
      );
      //blur
      filter: blur(10rem);
    }
    .circle2 {
      content: " ";
      position: absolute;
      bottom: -20%;
      right: 20%;
      width: 100rem;
      height: 100rem;
      border-radius: 50%;
      background: radial-gradient(
        33.86% 33.88% at 50% 50%,
        #930c6d 0%,
        rgba(74, 15, 15, 0) 100%
      );
      filter: blur(21px);
      z-index: 0;
    }
    .circle3 {
      content: " ";
      position: absolute;
      width: 100rem;
      height: 100rem;
      left: 20%;
      bottom: 0%;

      background: radial-gradient(
        38.86% 33.88% at 50% 50%,
        #170c93 0%,
        rgba(6, 23, 84, 0) 100%
      );
      filter: blur(21px);
      z-index: 0;
    }
  }
}
@media (max-width: 1100px) {
  p {
    font-size: 300% !important;
    max-width: calc(100% - 7rem);
  }
}
@media (max-width: 768px) {
  h2 {
    font-size: 6rem !important;
    margin-right: 1rem !important;
  }
  .about-gallery {
    grid-template-columns: 1fr 1fr !important;
    grid-template-rows: 1fr 1fr !important;
    p {
      padding-top: 5rem;
      grid-area: 2/1/3/3 !important;
      margin: 0 auto !important;
      padding-bottom: 20rem;
      text-align: center !important;
    }
  }
}
